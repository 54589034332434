export const asyncAwaitData = {
  section: 'Async-Await',
  items: [
    {
      title: 'Async-Await Basics',
      value: `The \`async\` and \`await\` keywords in JavaScript simplify working with asynchronous operations by allowing you to write asynchronous code that looks synchronous. An \`async\` function always returns a promise, and the \`await\` keyword pauses the execution of the function until the promise is resolved or rejected.`,
      link: '/infobook/javascript',
      linkHelper: 'Async',
    },
    {
      title: 'Promises and Resolve',
      value: `A promise in JavaScript represents a value that may be available now, or in the future, or never. Promises are used to handle asynchronous operations, providing methods like \`then\`, \`catch\`, and \`finally\`. You can create a promise using the \`Promise\` constructor and resolve it with \`resolve\` or handle errors with \`reject\`. Promises improve code readability compared to traditional callbacks.`,
      link: '/infobook/javascript',
      linkHelper: 'Async',
    },
    {
      title: 'Try-Catch',
      value: `Error handling in asynchronous code can be done using a "try - catch" block with \`async-await\`. This provides a more straightforward and readable way to handle errors compared to chaining \`catch\` methods on promises. Place the \`await\` calls inside the \`try\` block, and handle any errors in the \`catch\` block.`,
      link: '/infobook/javascript',
      linkHelper: 'Async',
    },
  ],
};

import React from 'react';

import { eventsData } from 'data/javascript/events';

import DescriptionEntry from 'components/description-entry';

const EventListeners = () => {
  const item = eventsData?.items.find(
    (item) => item.title === 'Event Listeners'
  );

  return <DescriptionEntry entry={item} />;
};

export default EventListeners;

export const functionsData = {
  section: 'Functions',
  items: [
    {
      title: 'Functions',
      value: `In JavaScript, functions are reusable blocks of code that are designed to perform specific tasks. They allow you to organize code, make it more modular, and reduce redundancy by avoiding repetition. Functions can accept inputs (called parameters), perform operations, and optionally return a value.`,
      link: '/infobook/javascript',
      linkHelper: 'Functions',
    },
    {
      title: 'Arrow Functions',
      value: `Arrow functions, introduced in ES6, provide a more concise syntax for writing functions in JavaScript. They are often used for short and simple functions that have unique characteristics that distinguish them from regular functions. By default an arrow function expects a single line return, and if you need the function to handle more than one line you must use squiggly brackets { }.`,
      link: '/infobook/javascript',
      linkHelper: 'Functions',
    },
    {
      title: 'Arrow Functions vs Functions - the differences',
      value: `Arrow functions and regular functions differ primarily in how they handle the \`this\` context. Arrow functions inherit \`this\` from their surrounding scope (lexical scoping), making them ideal for callbacks or functional programming where preserving context is important. Regular functions define their own \`this\` based on the call site, making them better suited for methods in objects or constructors. Arrow functions are more concise and cannot be used as constructors, while regular functions support \`arguments\` and the \`new\` keyword. Use arrow functions for brevity in simple tasks or callbacks and regular functions when you need their dynamic capabilities.`,
      link: '/infobook/javascript',
      linkHelper: 'Functions',
    },
  ],
};

import React from 'react';

import Text from 'components/text';
import HeaderText from 'components/header-text';

import CloseReleaseInfoButton from './CloseReleaseInfoButton';

const ReleaseNoteOpen = ({ entry, handleClick }) => {
  return (
    <section className="release-note-open">
      <div className="release-note-row-header">
        <HeaderText
          text="Release:"
          fontSize="24px"
          fontWeight="bold"
          margin="0 20px 0 0"
        />
        <Text text={entry?.version} fontSize="24px" />
      </div>
      <div className="release-note-row">
        <Text
          text="Date: "
          width="100px"
          fontSize="18px"
          fontWeight="bold"
          margin="0 0 10px 0"
        />
        <Text fontSize="18px" text={entry?.date} margin="0" />
      </div>
      <div className="release-note-row-scroll">
        <Text
          text="Change: "
          width="100px"
          fontSize="18px"
          fontWeight="bold"
          margin="0 0 10px 0"
        />
        <Text
          margin="0"
          width="85%"
          fontSize="18px"
          lineHeight="1.75"
          text={entry?.updates}
        />
      </div>
      <CloseReleaseInfoButton handleClick={handleClick} />
    </section>
  );
};

export default ReleaseNoteOpen;

import React from 'react';

import HeaderText from 'components/header-text';

import CodeExample from './CodeExample';
import JavaScriptFunctions from './JavaScriptFunctions';
import FunctionDifferences from './FunctionDifferences';
import JavaScriptArrowFunctions from './JavaScriptArrowFunctions';

import '../index.scss';

const sampleRegularFunction = `function addNumbers(numberA, numberB) {
    return numberA + numberB;
}

const summationValue = addNumbers(5, 7);
console.log(summationValue);`;

const sampleArrowFunction = `// single-line: no 'return' keyword needed to return a value
const addNumbersArrowFunction = (numberA, numberB) => numberA + numberB;

const summationValue = addNumbers(4, 6);
console.log(summationValue);

// multi-line: 'return' keyword needed to return value if desired
const addNumbersFunction = (numberA, numberB) => {
  console.log('adding numbers');
  return numberA + numberB;
};

const summationValue = addNumbers(4, 6);
console.log(summationValue);`;

const FunctionsTab = () => (
  <div className="tab-view">
    <HeaderText
      fontSize="30px"
      fontWeight="bold"
      margin="0 0 40px 0"
      text="JavaScript Functions"
    />
    <div className="inner-tab-content">
      <JavaScriptFunctions />
      <CodeExample codeString={sampleRegularFunction} />
      <JavaScriptArrowFunctions />
      <CodeExample codeString={sampleArrowFunction} />
      <FunctionDifferences />
    </div>
  </div>
);

export default FunctionsTab;

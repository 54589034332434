import React from 'react';

import { asyncAwaitData } from 'data/javascript/async';

import DescriptionEntry from 'components/description-entry';

const AsyncAwaitBasics = () => {
  const item = asyncAwaitData?.items.find(
    (item) => item.title === 'Async-Await Basics'
  );

  return <DescriptionEntry entry={item} />;
};

export default AsyncAwaitBasics;

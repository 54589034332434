import React from 'react';

import { variablesJSData } from 'data/javascript/variables';

import DescriptionEntry from 'components/description-entry';

const VarExample = () => {
  const item = variablesJSData?.items.find(
    (item) => item.title === 'Variables - var'
  );

  return <DescriptionEntry entry={item} />;
};

export default VarExample;

import HeaderText from 'components/header-text';

import { aboutJSData } from 'data/javascript/about';

import DescriptionEntry from 'components/description-entry';

import '../index.scss';

const AboutTab = () => (
  <div className="tab-view">
    <HeaderText
      fontSize="30px"
      fontWeight="bold"
      margin="0 0 40px 0"
      text="About JavaScript"
    />
    <div className="inner-tab-content">
      {aboutJSData?.items?.map((entry) => (
        <DescriptionEntry entry={entry} key={entry?.title} />
      ))}
    </div>
  </div>
);

export default AboutTab;

import React from 'react';

import { asyncAwaitData } from 'data/javascript/async';

import DescriptionEntry from 'components/description-entry';

const Promises = () => {
  const item = asyncAwaitData?.items.find(
    (item) => item.title === 'Promises and Resolve'
  );

  return <DescriptionEntry entry={item} />;
};

export default Promises;

export const eventsData = {
  section: 'Events',
  items: [
    {
      title: 'Events',
      value: `In JavaScript, events are actions or occurrences that happen in the browser, which JavaScript can respond to. Common events include user interactions such as clicks, key presses, and mouse movements, as well as system events like loading a page or resizing a window. Event listeners are used to attach specific behaviors to these events.`,
      link: '/infobook/javascript',
      linkHelper: 'Event Listeners',
    },
    {
      title: 'Event Listeners',
      value: `Event listeners are functions that "listen" for specified events on a target element. They are added using the \`addEventListener\` method, which allows you to execute a callback function whenever the event occurs. Event listeners provide flexibility in separating JavaScript logic from HTML.`,
      link: '/infobook/javascript',
      linkHelper: 'Events',
    },
    {
      title: 'Event Bubbling and Capturing',
      value: `JavaScript events follow a flow known as event propagation, which has two main phases: capturing and bubbling. Capturing phase occurs as the event travels down the DOM tree to the target, and bubbling phase occurs as the event travels back up the tree. You can control this behavior by specifying the third parameter in \`addEventListener\` as \`true\` (capturing) or \`false\` (bubbling). Understanding this flow is essential for managing event delegation.`,
      link: '/infobook/javascript',
      linkHelper: 'Event Propagation',
    },
  ],
};

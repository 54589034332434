export const classesData = {
  section: 'Classes',
  items: [
    {
      title: 'Classes',
      value: `In JavaScript, classes are a template for creating objects. They were introduced in ES6 as a more intuitive way to work with constructor functions and prototypes. A class can encapsulate data and behavior (methods) and is used to instantiate objects with similar properties and methods.`,
      link: '/infobook/javascript',
      linkHelper: 'Classes',
    },
    {
      title: 'Class Syntax',
      value: `The class syntax in JavaScript uses the \`class\` keyword, followed by the class name and a body enclosed in curly braces. Inside the class body, you define the constructor method for initializing objects and other methods. Classes also support inheritance through the \`extends\` keyword, allowing you to create subclasses that inherit properties and methods from parent classes.`,
      link: '/infobook/javascript',
      linkHelper: 'Classes',
    },
    {
      title: 'Classes vs Prototypes',
      value: `Although classes in JavaScript provide a cleaner and more readable syntax, they are syntactic sugar over the prototype-based inheritance model. Classes do not introduce a new inheritance model but simplify working with prototypes. Use classes when you prefer a structured and declarative approach, but remember the underlying mechanics still use prototypes.`,
      link: '/infobook/javascript',
      linkHelper: 'Classes',
    },
  ],
};

import React from 'react';

import HeaderText from 'components/header-text';

import CodeExample from './CodeExample';
import JavaScriptClasses from './JavaScriptClasses';
import ClassSyntax from './ClassSyntax';

import '../index.scss';
import ClassVsPrototype from './ClassVsPrototype';

const sampleClassCode = `class Person {
  constructor(name, age) {
      this.name = name;
      this.age = age;
  }

  greet() {
      return \`Hello, my name is \${this.name} and I am \${this.age} years old.\`;
  }
}

// Creating an instance of the Person class
const person1 = new Person('Alice', 25);

// Calling the greet method
console.log(person1.greet());`;

const ClassesTab = () => (
  <div className="tab-view">
    <HeaderText
      fontSize="30px"
      fontWeight="bold"
      margin="0 0 40px 0"
      text="JavaScript Classes"
    />
    <div className="inner-tab-content">
      <JavaScriptClasses />
      <ClassSyntax />
      <ClassVsPrototype />
      <CodeExample codeString={sampleClassCode} />
    </div>
  </div>
);

export default ClassesTab;

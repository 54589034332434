import React from 'react';

import { classesData } from 'data/javascript/classes';

import DescriptionEntry from 'components/description-entry';

const JavaScriptClasses = () => {
  const item = classesData?.items.find((item) => item.title === 'Classes');

  return <DescriptionEntry entry={item} />;
};

export default JavaScriptClasses;

export const variablesJSData = {
  section: 'Variables',
  items: [
    {
      title: 'Variables - var',
      value: `Using "var" declares a globally-scoped variable that can be reassigned. Variables declared with "var" are not block-scoped. Code blocks are statements/code between curly brackets { }.`,
      link: '/infobook/javascript',
      linkHelper: 'Variables',
    },
    {
      title: 'Variables - let',
      value: `Using "let" declares a blocked-scoped variable that can be reassigned.`,
      link: '/infobook/javascript',
      linkHelper: 'Variables',
    },
    {
      title: 'Variables - const',
      value: `Using "const" declares a blocked-scoped variable that can NOT can be reassigned.`,
      link: '/infobook/javascript',
      linkHelper: 'Variables',
    },
  ],
};

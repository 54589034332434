import React from 'react';

import HeaderText from 'components/header-text';

import CodeExample from './CodeExample';
import EventListeners from './EventListeners';
import EventsBubbling from './EventsBubbling';
import JavaScriptEvents from './JavaScriptEvents';

import '../index.scss';

const sampleEventCode = `document.getElementById('someButton').addEventListener('click', () => {
    alert('Button was clicked!');
});

// Adding a mouseover event
document.getElementById('clickMeButton').addEventListener('mouseover', () => {
    console.log('Mouse is over the button.');
});

// Dynamically removing the click event
document.getElementById('clickMeButton').removeEventListener('click', () => {
    alert('Button was clicked!');
});`;

const EventsTab = () => (
  <div className="tab-view">
    <HeaderText
      fontSize="30px"
      fontWeight="bold"
      margin="0 0 40px 0"
      text="JavaScript Events"
    />
    <div className="inner-tab-content">
      <JavaScriptEvents />
      <EventListeners />
      <CodeExample codeString={sampleEventCode} />
      <EventsBubbling />
    </div>
  </div>
);

export default EventsTab;

import React from 'react';

import HeaderText from 'components/header-text';

import Promises from './Promises';
import TryCatch from './TryCatch';
import CodeExample from './CodeExample';
import JavaScriptEvents from './AsyncAwaitBasics';

import '../index.scss';

const sampleAsyncAwaitCode = `// Simulating an asynchronous operation with a promise
const fetchData = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const success = Math.random() > 0.5; // Random success/failure
            if (success) {
                resolve('Data fetched successfully!');
            } else {
                reject('Error fetching data.');
            }
        }, 2000); // Simulates a 2-second delay
    });
};

// Using async/await to handle the asynchronous operation
const getData = async () => {
    try {
        const data = await fetchData();
        console.log(data);
    } catch (error) {
        console.error(error);
    }
};`;

const AsyncTab = () => (
  <div className="tab-view">
    <HeaderText
      fontSize="30px"
      fontWeight="bold"
      margin="0 0 40px 0"
      text="Asynchronous JavaScript"
    />
    <div className="inner-tab-content">
      <JavaScriptEvents />
      <Promises />
      <TryCatch />
      <CodeExample codeString={sampleAsyncAwaitCode} />
    </div>
  </div>
);

export default AsyncTab;

import React from 'react';

import { eventsData } from 'data/javascript/events';

import DescriptionEntry from 'components/description-entry';

const JavaScriptClasses = () => {
  const item = eventsData?.items.find((item) => item.title === 'Events');

  return <DescriptionEntry entry={item} />;
};

export default JavaScriptClasses;

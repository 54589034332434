import React, { useContext } from 'react';

import GlobalContext from 'GlobalContext';

import Card from 'components/card';
import Text from 'components/text';
import Button from 'components/button';
import TextBanner from 'components/text-banner';

import IconWithEffect from './IconWithEffect';
import ContactUsAccentText from './AboutAccentText';

import './index.scss';

const email = 'techtutztutorials@gmail.com';

const AboutView = () => {
  const {
    setShowNotification,
    setNotificationText,
    setNotificationStatus,
  } = useContext(GlobalContext);

  const copy = async (textToCopy) =>
    await navigator.clipboard.writeText(textToCopy);

  const handleCopy = () => {
    copy(email);
    setNotificationText('Email copied to clipboard!');
    setNotificationStatus('success');
    setShowNotification(true);
  };

  return (
    <Card headerText="About">
      <div className="about-container">
        <IconWithEffect />
        <Text text="A fresh take on learning" margin="20px 0" />
        <TextBanner bannerText="web development" />
        <ContactUsAccentText />
        <Text
          width="85%"
          textAlign="center"
          lineHeight="1.5"
          margin="20px auto 40px auto"
          text="Below is our support email, click it to quickly copy it!"
        />
        <Button buttonText={email} handleClick={handleCopy} />
      </div>
    </Card>
  );
};

export default AboutView;

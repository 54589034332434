export const learnJSData = {
  section: 'Learn',
  items: [
    {
      title: 'Workspace',
      value:
        'JavaScript is a computer language, and you will need an IDE (integrated development environment) or a code editor to work with it. If you do not already have one installed (or are not sure which one to install) you can check out our Resources page for our recommendations.',
      link: '/infobook/javascript',
      linkHelper: 'Learn',
    },
    {
      title: 'File Naming',
      value:
        'The system needs to know this document is JavaScript and not a regular text document. To do this, end the file name with .js when saving - example: index.js or myFile.js.',
      link: '/infobook/javascript',
      linkHelper: 'Learn',
    },
    {
      title: 'JavaScript Flow',
      value:
        'Through variables, events, functions, classes, conditional control flow statements, and DOM manipulation - JavaScript stores, works with, and enhances data and seamlessly interacts with the user interface to create dynamic, interactive, and responsive web experiences.',
      link: '/infobook/javascript',
      linkHelper: 'Learn',
    },
    {
      title: 'Script Example',
      value:
        'Below is a basic example of using JavaScript to do something useful. Here we are declaring a variable, calling it currentDateTime, and then  assigning a value. Finally we can log our value to the console to verify it worked.',
      link: '/infobook/javascript',
      linkHelper: 'Learn',
    },
  ],
};

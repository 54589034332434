import React from 'react';

import { classesData } from 'data/javascript/classes';

import DescriptionEntry from 'components/description-entry';

const ClassVsPrototype = () => {
  const item = classesData?.items.find(
    (item) => item.title === 'Classes vs Prototypes'
  );

  return <DescriptionEntry entry={item} />;
};

export default ClassVsPrototype;

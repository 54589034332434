import React from 'react';

import HeaderText from 'components/header-text';

import LetExample from './LetExample';
import VarExample from './VarExample';
import ConstExample from './ConstExample';

import CodeExample from './CodeExample';

import '../index.scss';

const sampleLetCode = `let myName = 'Billy';

// we can reassign value if desired
myName = 'Sam'`;

const sampleVarCode = `var myLastName = 'Bobby';

// we can reassign value if desired
myLastName = 'Samson'`;

const sampleConstCode = `const myLastName = 'Bobby';

// see error when we try to reassign
myLastName = 'Samson'`;

const VariablesTab = () => (
  <div className="tab-view">
    <HeaderText
      fontSize="30px"
      fontWeight="bold"
      margin="0 0 40px 0"
      text="JavaScript Variables"
    />
    <div className="inner-tab-content">
      <VarExample />
      <CodeExample codeString={sampleVarCode} />
      <ConstExample />
      <CodeExample codeString={sampleConstCode} />
      <LetExample />
      <CodeExample codeString={sampleLetCode} />
    </div>
  </div>
);

export default VariablesTab;

import React from 'react';

import { functionsData } from 'data/javascript/functions';

import DescriptionEntry from 'components/description-entry';

const JavaScriptArrowFunctions = () => {
  const item = functionsData?.items.find(
    (item) => item.title === 'Arrow Functions'
  );

  return <DescriptionEntry entry={item} />;
};

export default JavaScriptArrowFunctions;

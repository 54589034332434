import React from 'react';

import { functionsData } from 'data/javascript/functions';

import DescriptionEntry from 'components/description-entry';

const FunctionDifferences = () => {
  const item = functionsData?.items.find(
    (item) =>
      item.title === 'Arrow Functions vs Functions - the differences'
  );

  return <DescriptionEntry entry={item} />;
};

export default FunctionDifferences;
